import React from "react"
import Seo from "../components/SEO"
import Contact from "../components/Contact/Contact"
import BannerModule from "../components/BannerModule/BannerModule"
import Faq from "../components/Faq/Faq"
import Products from "../components/Features/Features"
import Layout from "../components/Layout"
import {getGatsbyStaticImage} from "../constants/products-image"

const Producttemplate = ({pageContext}) => {

  const {
    title,
    SEOtitle,
    SEOdescription,
    // gatsbyPath,
    // imgUrl,
    preview,
    request,
    solution,
    benefits,
    // tecnologies
  } = pageContext.product

  return (
    <>
      <Seo
      title={SEOtitle}
      description={SEOdescription} />

      <Layout>

        <BannerModule
        title={title}
        subTitle={preview}
        textButton="Sei interessato?"
        >

          {getGatsbyStaticImage(title)}

          <div className={`absolute w-full z-10 h-full top-0 bottom-0 left-0 right-0 bg-black opacity-50`}/>

        </BannerModule>
        
        <div className={`flex flex-col md:flex-row mx-auto
        w-10/12 md:w-11/12 lg:w-2/3 pt-20 md:pt-32 lg:pt-40 xl:pt-48
        `}>
          {solution && (
            <div className="md:basis-1/2 pb-10 md:pb-0 text-slate-400">
              
              <h2 className={`border-b-2 border-zinc-700 text-white
              font-bold text-3xl md:text-4xl xl:text-4xl pb-1 w-fit `} >
                La Richiesta
                <span className="text-yellow-500 text-3xl md:text-4xl">.</span>
              </h2>
              <p className="mt-10 mb-14 md:max-w-7xl text-base md:text-lg">
                {request}
              </p>
              <h2 className={`border-b-2 border-zinc-700 text-white
              font-bold text-3xl md:text-4xl xl:text-4xl pb-1 w-fit `}>
                La Soluzione
                <span className="text-yellow-500 text-3xl md:text-4xl">.</span>
              </h2>
              <p className="mt-10 mb-14 md:max-w-7xl text-base md:text-lg">
                {solution}
              </p>

            </div>
          )}

          {benefits && (
            <div className="md:basis-1/2 pl-0 md:pl-10">
              {benefits.map((item, index) => {
                return (
                  <Faq
                    key={index}
                    title={item?.label}
                    description={item?.description}
                    textStyle={"mt-10 mb-14 md:max-w-7xl text-base md:text-lg text-slate-400 px-5"}
                  />
                )
              })}
            </div>
          )}
        </div>
        {/* {gallery && (
          <ProductGallery className="section">
            <div className="container container__tight">
              {gallery.map((item, index) => {
                let galleyImage = getImage(item)
                return <GatsbyImage key={index} image={galleyImage} />
              })}
            </div>
          </ProductGallery>
        )} */}
        
        <Products
        title="Prodotti in primo piano"
        introduction="Sfoglia il catalogo!"
        />

        <Contact
        title = "Ti abbiamo incuriosito?"
        object = {title} />
        
      </Layout>
    </>
  )
}

export default Producttemplate
